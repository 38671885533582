<template>
  <Container>
    <div
      class="mx-auto w-full max-w-content-container px-small pb-24 pt-8 lg:px-14 lg:pb-32 lg:pt-10 2xl:px-0"
    >
      <slot />
    </div>
  </Container>
</template>
<script>
import Container from '~/components/overviews/Container.vue'

export default {
  name: 'OverviewsLayout',
  components: { Container },
}
</script>
